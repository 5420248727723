import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import { useWeb3React } from '@web3-react/core';
import { getTokenAvatar } from '../../utils/utils';

const StyledAvatar = withStyles({
  root: {

  },
})(Avatar);

const TokenAvatar = ({ tokenAddress, Size }) => {
  const { chainId } = useWeb3React();
  const [img, setImg] = React.useState();

  React.useEffect(() => {
    if (chainId) {
      const tokenimage = getTokenAvatar(tokenAddress, chainId);
      setImg(tokenimage);
    }

  }, [chainId]);
  return (
    <StyledAvatar
      src={img}
    />
  );
};

export default TokenAvatar;
