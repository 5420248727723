import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { abis } from '@project/contracts';
import { useToasts } from 'react-toast-notifications';
import { makeContract } from '../utils/utils';

const useJoinPool = (poolAddress, poolAmountOut, maxAmountsIn) => {
  const { account, library } = useWeb3React();
  const { addToast } = useToasts();

  const join = async (
    _poolAmountOut,
    _maxAmountsIn,
  ) => {
    addToast('Waiting for transaction success...', {
      appearance: 'info',
      autoDismiss: true,
    });
    const contract = makeContract(library, abis.indexpool, poolAddress);
    await contract.methods.joinPool(
      _poolAmountOut.toString(),
      _maxAmountsIn,
    ).send({ from: account }).then(() => {
      addToast('Transaction Success!', {
        appearance: 'success',
        autoDismiss: true,
      });

    }).catch(err => {
      if (err.message.includes('User denied transaction signature')) {
        addToast('Denied Transaction', {
          appearance: 'error',
          autoDismiss: true,
        });
      } else {
        addToast('Transaction Failed', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    });

  };

  const handleJoin = React.useCallback(
    async () => {
      await join(
        poolAmountOut,
        maxAmountsIn,
      );
    },
    [account, poolAddress, poolAmountOut, maxAmountsIn],
  );

  return { onJoinPool: handleJoin };
};

export default useJoinPool;
