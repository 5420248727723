import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { useWeb3React } from '@web3-react/core';

import TokenAvatar from '../../../components/avatars/TokenAvatar';
import Card from '../../../components/Card';
import Button from '../../../components/Button';

import useCurrentTokens from '../../../hooks/useCurrentTokens';
import useTokenInfo from '../../../hooks/useTokenInfo';

import { useStyles } from './styles.module';
import { getTokenAvatar } from '../../../utils/utils.js';

const PoolCard = ({ poolAddress }) => {
    const classes = useStyles();
    const { chainId } = useWeb3React();

    const poolAvatar = getTokenAvatar(poolAddress, chainId);
    const currentTokens = useCurrentTokens(poolAddress);
    const { name, symbol } = useTokenInfo(poolAddress);

    const otherToken = useMemo(() => (
      currentTokens && currentTokens.map(item => (<TokenAvatar tokenAddress={item} key={item} />))
    ), [currentTokens]);

    return (
      <Grid item>
        <Card className={classes.card}>
          <Avatar src={`${name === 'BSC Top 10' ? '/assets/coins/biT10.png' : '/assets/logos/biAPE.png'}`} className={classes.avatar} />
          <div>{name}</div>
          <AvatarGroup max={5} className={classes.avatarGroup}>
            {otherToken}
          </AvatarGroup>
          <Link to={`/funds/${poolAddress}`}><Button outLine>{symbol} Dashboard</Button></Link>
        </Card>
      </Grid>
    );
};

export default PoolCard;
