import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import { abis } from '@project/contracts';
import { makeContract } from '../utils/utils';

const usePoolTokenWeights = (poolAddress, poolTokens) => {
    const { account, library } = useWeb3React();
    const [weights, setTokenWeights] = useState([new BigNumber(0)]);

    useEffect(() => {

        const fetchWeights = async (tokens) => {
            if (!tokens.length) {
                return;
            }
            const contract = makeContract(library, abis.indexpool, poolAddress);
            const newWeights = await Promise.all(tokens.map((token) => contract.methods.getDenormalizedWeight(token).call()));
            const newBNWeights = newWeights.map((val) => new BigNumber(val));
            setTokenWeights(newBNWeights);
        };

        if (account && library && poolTokens && poolAddress) {
            fetchWeights(poolTokens);
        }

    }, [account, library, poolAddress, poolTokens]);

    return weights;
};

export default usePoolTokenWeights;
