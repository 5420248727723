import {
  makeStyles,
} from '@material-ui/core/styles';
import { theme } from '../../theme';

export const useStyles = makeStyles(() => ({
  container: {
    padding: '30px',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.main,
    minHeight: '80vh',
    },
  button: {
      color: theme.palette.text.primary,
  },
  title: {
    textShadow: `0px 4px 12px ${theme.palette.secondary.main}`,
  },
  backgroundVideo: {
    position: 'fixed', zIndex: '-99', width: '100vw', height: '100vh',
  },
  loader: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
  },
}));
