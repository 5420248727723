import React from 'react';
import classNames from 'classnames';
import { Button as MaterialButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useStyles } from './styles.module';

const Button = ({ children, className, onClick, outLine, loader, disabled, ...props }) => {
  const classes = useStyles();
  return (
    <MaterialButton
      className={classNames(className, { [classes.button]: outLine })}
      onClick={loader ? () => {} : onClick}
      disabled={loader || disabled}
      {...props}
    >
      {loader ? <CircularProgress /> : children}
    </MaterialButton>
  );
};

export default Button;
