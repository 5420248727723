import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';

import BigNumber from 'bignumber.js';

import Avatar from '@material-ui/core/Avatar';
import { useWeb3React } from '@web3-react/core';
import ProgressBar from '../../../../../components/ProgressBar';

import { formatter, getTokenImg } from '../../../../../utils/utils';

import useTokenInfo from '../../../../../hooks/useTokenInfo';

import { useStyles } from './styles.module';

const TokenInfo = ({
  poolInfo, poolToken, usdPrice, weight, balance,
}) => {
  const classes = useStyles();
  const { library } = useWeb3React();
  const { name, symbol, avatar } = useTokenInfo(poolToken);

  const weightPercentage = useMemo(() => (
    (poolInfo && poolInfo.totalWeight) && ((100 * Number(weight)) / Number(poolInfo.totalWeight))
  ), [poolInfo, weight]);

  const usdValue = useMemo(() => (
    usdPrice && new BigNumber(usdPrice).times(balance).div(1e18).toNumber()
  ), [balance, usdPrice]);

  const balanceValue = useMemo(() => (
    balance && formatter.format(Number(library.utils.fromWei(balance.toFixed(), 'ether')))
  ), [balance, library]);

  return (
    <Grid
      container
      spacing={1}
      className={classes.root}
      direction="row"
    >
      <>

        <Grid container item xs>
          <Grid
            container
            item
            xs={12}
            alignItems="flex-start"
            justifyContent="space-between"
            className={classes.wrap}
          >
            <Grid item className={classes.row}>
              <Grid item>
                <Avatar src={getTokenImg(symbol) ? getTokenImg(symbol) : avatar} className={classes.avatar} />
              </Grid>
              <Grid item className={classes.tokenNameColumn}>
                <Grid item>
                  <span>{symbol}</span>
                  <span className={classes.tokenName}>{name}</span>
                </Grid>
                {
                  (balanceValue && usdValue) && (
                  <Grid item className={classes.text}>
                    {balanceValue}{' = $'}{formatter.format(usdValue)}
                  </Grid>
                  )
                }
              </Grid>
            </Grid>
            <Grid item className={classes.column}>
              {usdPrice && <Grid item>${formatter.format(usdPrice)}</Grid>}
              {weightPercentage && <Grid item className={classes.text}>{weightPercentage.toFixed(2)}%</Grid>}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ProgressBar percentage={weightPercentage || '0'} />
          </Grid>
        </Grid>
      </>
    </Grid>
  );
};

TokenInfo.defaultProps = {
  handleSetUsdValue: () => { },
};

export default TokenInfo;
