import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import Avatar from '@material-ui/core/Avatar';

import { useWeb3React } from '@web3-react/core';
import ApproveButton from '../../buttons/ApproveButton.component';

import { formatter, getTokenImg } from '../../../utils/utils.js';
import useTokenInfo from '../../../hooks/useTokenInfo.js';
import useTokenBalance from '../../../hooks/useTokenBalance.js';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '13px 18px',
  },
  itemLogo: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
  },
  item: {
    width: '25%',
  },
}));

const TokenItem = ({
  value,
  index,
  poolAmounts,
  address,
  onDemandGreaterThanBalance,
  withButton,
}) => {
  const classes = useStyles();
  const { symbol, avatar } = useTokenInfo(value);
  const balance = useTokenBalance(value);
  const { library } = useWeb3React();
  useEffect(() => {
    const bnDemandValue = Number(poolAmounts[index]);
    if (onDemandGreaterThanBalance && bnDemandValue > 0) {
      const newValue = bnDemandValue > Number(balance);
      onDemandGreaterThanBalance(symbol, newValue);
    }
  }, [balance, onDemandGreaterThanBalance, symbol, poolAmounts]);
  return (
    <div className={classes.container}>
      <div className={classes.itemLogo}>
        <ListItemAvatar>
          <Avatar alt="Avatar" src={getTokenImg(symbol) ? getTokenImg(symbol) : avatar} />
        </ListItemAvatar>
        {symbol}
      </div>
      <div className={classes.item}>
        {formatter.format(library.utils.fromWei(poolAmounts[index], 'ether'))}
      </div>
      {withButton && (
        <div className={classes.item}>
          <ListItem>
            <ApproveButton poolAddress={address} poolTokenAddress={value} />
          </ListItem>
        </div>
      )}
    </div>
  );
};

const TokenList = ({
  poolAddress,
  poolTokens,
  poolAmounts,
  withButton,
  onDemandGreaterThanBalance,
}) => {
  const classes = useStyles();

  return (
    <>
      {poolTokens && poolAmounts && poolAddress && (
        <Grid container>
          <List className={classes.root} dense>
            {poolTokens.map((value, index) => (
              <TokenItem
                key={value}
                address={poolAddress}
                value={value}
                index={index}
                poolAmounts={poolAmounts}
                withButton={withButton}
                onDemandGreaterThanBalance={onDemandGreaterThanBalance}
              />
            ))}
          </List>
        </Grid>
      )}
    </>
  );
};

TokenList.defaultProps = {
  handleMintDisabling: () => {},
  onDemandGreaterThanBalance: () => {},
};

export default TokenList;
