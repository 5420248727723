import BigNumber from 'bignumber.js';
import { addresses, abis } from '@project/contracts';

import autofarm from '../assets/coins/autofarm.webp';
import bakerytoken from '../assets/coins/bakerytoken.png';
import bscpad from '../assets/coins/bscpad.webp';
import bunny from '../assets/coins/bunny.webp';
import cake from '../assets/coins/cake.png';
import safepal from '../assets/coins/safepal.png';
import trust from '../assets/coins/trust.png';
import venus from '../assets/coins/venus.png';
import wbnb from '../assets/coins/wbnb.png';
import swipe from '../assets/coins/swipe.png';
import ellipsis from '../assets/coins/ellipsis.png';
import banana from '../assets/coins/banana.png';
import bifi from '../assets/coins/bifi.png';
import bison from '../assets/coins/bison.png';
import dragonary from '../assets/coins/dragonary.webp';
import bitfresh from '../assets/coins/bitfresh.png';
import jdi from '../assets/coins/jdi.svg';
import hifi from '../assets/coins/hifi.jpg';
import lyptus from '../assets/coins/lyptus.webp';
import type from '../assets/coins/type.jpg';
import apeSwap from '../assets/coins/apeSwap.svg';

import biT10 from '../assets/coins/biT10.png';

export const MAX_UINT = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
export const MAX_UINT_96 = '0xffffffffffffffffffffffff';

export const formatter = new Intl.NumberFormat('en-US',
    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
);

export const SHORT_TWAP_MIN = '60';
export const SHORT_TWAP_MAX = '116000';

export const makeContract = (library, abi, address) => new library.eth.Contract(abi, address);
export const isApproved = (allowance) => Number(allowance) >= Number(MAX_UINT_96);

// TODO: rm tokenname + symbol + address
export const getTokenAvatar = (coinaddress, chainId) => {

    switch (coinaddress) {
        case addresses[chainId].LYPTUS:
            return lyptus;
        case addresses[chainId].TAPE:
            return type;
        case addresses[chainId].HIFI:
            return hifi;
        case addresses[chainId].JDI:
            return jdi;
        case addresses[chainId].BITFRESH:
            return bitfresh;
        case addresses[chainId].DRAGONARY:
            return dragonary;
        case addresses[chainId].WBNB:
            return wbnb;
        case addresses[chainId].CAKE:
            return cake;
        case addresses[chainId].SWIPE:
            return swipe;
        case addresses[chainId].BAKE:
            return bakerytoken;
        case addresses[chainId].VENUS:
            return venus;
        case addresses[chainId].ELLIPSIS:
            return ellipsis;
        case addresses[chainId].BANANA:
            return banana;
        case addresses[chainId].SAFEPAL:
            return safepal;
        case addresses[chainId].TWT:
            return trust;
        case addresses[chainId].BIFI:
            return bifi;
        case addresses[chainId].BISON:
            return bison;
        case addresses[chainId].biT10:
            return biT10;
        case addresses[chainId].apeSwap:
            return apeSwap;
        case addresses[chainId].mockPool:
            return biT10;
        default:
            return '';
    }
};

export const percentageOf = (outOff, value) => {
    const result = (Number(value) * 100) / Number(outOff);
    return (result.toString());
};

export const formatNumber = (value) => formatter.format();

export const copyTextToClipboard = (text) => navigator.clipboard.writeText(text);

export function sortTokens(tokenA, tokenB) {
    return tokenA.toLowerCase() < tokenB.toLowerCase()
        ? [tokenA, tokenB]
        : [tokenB, tokenA];
}

export const getTokenImg = symbol => {
  let imgPath = '';
  if (symbol === 'BNB') {
    imgPath = '/assets/coins/bnb.svg';
  } else if (symbol === 'BUSD') {
    imgPath = '/assets/coins/busd.svg';
  } else if (symbol === 'USDC') {
    imgPath = '/assets/coins/usdc.svg';
  } else if (symbol == 'CYT') {
    imgPath = '/assets/coins/CYT-LOGO-1.webp';
  } else if (symbol == 'HIFI') {
    imgPath = '/assets/coins/HIFI.png';
  } else if (symbol == 'HIFI') {
    imgPath = '/assets/coins/JDI.webp';
  } else if (symbol == 'LYPTUS') {
    imgPath = '/assets/coins/lyptus.png';
  } else if (symbol == 'TAPE') {
    imgPath = '/assets/coins/toolape.png';
  } else if (symbol == 'JDI') {
    imgPath = '/assets/coins/JDI.webp';
  }
  
  return imgPath;
};
