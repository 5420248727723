import React, { useState } from 'react';
import { Grid, Button, ButtonGroup } from '@material-ui/core';
import MaterialCard from '@material-ui/core/Card';

import CircularProgress from '@material-ui/core/CircularProgress';

import MintPanel from '../../panels/mintpanel/MintPanel.component';
import BurnPanel from '../../panels/burnpanel/BurnPanel.component';

import { useStyles } from './ActionCard.styles';

const ActionCard = ({ poolInfo }) => {
  const [currentActionTab, setCurrentActionTab] = useState('MINT');
  const classes = useStyles({ currentActionTab });
  const { allTokensInfo } = poolInfo;

  return (
    <MaterialCard className={classes.card}>
      <Grid container alignItems="flex-start">
        <div className={classes.header}>
          <ButtonGroup className={classes.buttonGroup}>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              className={classes.button}
              onClick={() => setCurrentActionTab('MINT')}
            >
              Mint
            </Button>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              className={classes.button}
              onClick={() => setCurrentActionTab('BURN')}
            >
              Burn
            </Button>
          </ButtonGroup>
        </div>
        <Grid item xs={12} className={classes.content}>
          {(poolInfo && allTokensInfo.length) ? (
            <>
              {currentActionTab === 'MINT' && <MintPanel poolInfo={poolInfo} />}
              {currentActionTab === 'BURN' && <BurnPanel poolInfo={poolInfo} />}
            </>
          ) : (
            <CircularProgress color="primary" />
          )}
        </Grid>
      </Grid>
    </MaterialCard>
  );
};

export default ActionCard;
