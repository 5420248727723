import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  buttonGroup: {
    marginBottom: '10px',
  },
  buttonTab: {
    color: 'white',
    marginRight: '20px',
    background: 'none',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
  },
  activeButton: {
    fontWeight: '700',
    borderBottom: 'white solid 2px',
  },
  container: {
    background: 'rgba(0, 0, 0, 0.25)',
    borderRadius: '16px',
    margin: '16px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  selectContainer: {
    maxHeight: '285px',
    overflow: 'auto',
    padding: '0 18px',
    height: '100%',
  },
  mintButton: {
    marginTop: '16px',
    borderRadius: '100px',
    width: '100%',
  },
  arrowContainer: {
    display: 'flex',
    borderRadius: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    background: 'rgba(0, 0, 0, 0.25)',
    width: '30px',
    height: '30px',
    color: '#F0B90B',
  },
  inputContainerBot: {
    marginTop: '16px',
  },
  inputContainer: {
    marginBottom: '16px',
  },
  inputContainerMulti: {
    margin: '32px 0 16px',
  },
  containerTitle: {
    margin: '16px',
    textAlign: 'center',
  },
  containerTitleMain: {
    color: '#F0B90B',
    fontWeight: 'bold',
  },
});
