import React, { useState, useEffect, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Button } from '@material-ui/core';
import TokenList from '../../lists/tokenlist/TokenList.component.js';
import InputToken from '../../inputs/InputToken.js';
import { useStyles } from './MintPanel.styles.js';
import { calcAllInGivenPoolOut } from '../../../utils/bmath.js';
import useJoinPool from '../../../hooks/useJoinPool.js';
import useAllBalanceAvailable from '../../../hooks/useAllBalanceAvailable.js';
import useCheckIfAllApproved from '../../../hooks/useCheckIfAllApproved.js';

const MultiMint = ({ poolInfo, inputValue, inputError, handleChange }) => {
  const { library } = useWeb3React();
  const classes = useStyles();
  const [amounts, setAmounts] = useState(poolInfo.tokens.map(() => '0'));
  const [demandBalanceMap, setDemandBalanceMap] = useState({});
  const [isMintDisabled, setIsMintDisabled] = useState(true);
  const [isDemandGreaterThanBalance, setIsDemandGreaterThanBalance] = useState(true);
  const hasBalanceAvailable = useAllBalanceAvailable(poolInfo, amounts, inputValue);
  const checkIfAllApproved = useCheckIfAllApproved(poolInfo);

  const { onJoinPool } = useJoinPool(
    poolInfo.address,
    library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether'),
    amounts,
  );

  const handleAmounts = useCallback((inputAmount, _poolInfo) => {
    if (_poolInfo !== undefined && _poolInfo.balances.length > 0 && inputAmount > 0) {
      const _amounts = calcAllInGivenPoolOut(_poolInfo.balances, _poolInfo.totalSupply, inputAmount);
      setAmounts(_amounts);
    }
  }, []);

  useEffect(() => {
    const values = Object.values(demandBalanceMap);
    if (values.length === poolInfo.tokens.length) {
      const isAnyDemandGreaterThanBalance = values.find(val => val);
      if (isAnyDemandGreaterThanBalance) {
        setIsDemandGreaterThanBalance(isAnyDemandGreaterThanBalance);
      }
    }
  }, [demandBalanceMap, poolInfo.tokens.length]);

  useEffect(() => {
    handleAmounts(library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether'), poolInfo);
    setIsMintDisabled(false);
  }, [handleAmounts, inputValue, poolInfo, checkIfAllApproved, hasBalanceAvailable, library.utils]);

  const demandGreaterThanBalanceHandler = useCallback((tokenSymbol, value) => {
    setDemandBalanceMap(prevDemandBalanceMap => ({
      ...prevDemandBalanceMap,
      [tokenSymbol]: value,
    }));
  }, []);

  const wrappedHandleChange = value => {
    handleChange({
      value,
      handleAmounts,
      setAmounts,
      setIsDemandGreaterThanBalance,
    });
  };

  return (
    <div>
      <div>Multi asset entry enables you to mint biT10 according to its asset allocation.</div>
      <div className={classes.container}>
        <TokenList
          poolAddress={poolInfo.address}
          poolAmounts={amounts}
          poolTokens={poolInfo.tokens}
          withButton
          onDemandGreaterThanBalance={demandGreaterThanBalanceHandler}
        />
      </div>
      <div className={classes.arrowContainer}>
        {' '}
        <ArrowDownwardIcon />
      </div>
      <div className={classes.inputContainerMulti}>
        <InputToken
          onChange={wrappedHandleChange}
          tokenInfo={{
            symbol: poolInfo.symbol,
            address: poolInfo.address,
          }}
          size="large"
          error={inputError}
          value={inputValue.toString()}
          gradient
          header={
            <div>
              Shares to
              <b> mint</b>
            </div>
          }
        />
      </div>
      {Number(inputValue) > 0 ? (
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={isMintDisabled || !checkIfAllApproved || inputValue <= 0 || isDemandGreaterThanBalance}
          onClick={onJoinPool}
          className={classes.mintButton}
        >
          MINT
        </Button>
      ) : (
        <Button variant="outlined" size="large" className={classes.mintButton}>
          Enter an Amount
        </Button>
      )}
    </div>
  );
};

export default MultiMint;
