import React from 'react';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';

import Card from '../../../../components/Card';

import { formatter } from '../../../../utils/utils';
import { copyTextToClipboard } from '../../../../helpers/functions.helper';

import { CopyIcon, ArrowUpIcon } from '../../../../constants/icons.constants';
import { BSC_SCAN_URL } from '../../../../constants/global.constants';

import { useStyles } from './styles.module';

const PoolHeader = ({ poolInfo }) => {
  const classes = useStyles();

  return (
    <Grid item container justifyContent="space-between" className={classes.container} alignItems="stretch">
      <Grid item component={Card} className={classes.card}>
        <img src={`${poolInfo?.name === 'BSC Top 10' ? '/assets/coins/biT10.png' : '/assets/logos/biAPE.png'}`} alt="bit10" className={classes.avatar} />
        <div className={classNames(classes.text, classes.withImg)}>
          <div className={classes.bsc}>
            <div className={classes.title}>{poolInfo?.name}</div>
            <div className={classes.icon} onClick={() => copyTextToClipboard(poolInfo?.address)}>{CopyIcon}</div>
            <a className={classes.icon} href={`${BSC_SCAN_URL}${poolInfo.address}`} target="_blank" rel="noreferrer">{ArrowUpIcon}</a>
          </div>
          <div className={classNames(classes.price, classes.priceWithImg)}>
            <div>${formatter.format(poolInfo?.tvl.toNumber() / poolInfo?.totalSupply.div(1e18))}</div>
            {/* <div className={classes.green}> +0.21 (+2.53%)</div> */}
          </div>
          <div className={classes.supply}>Supply: {formatter.format(poolInfo?.totalSupply.div(1e18))}</div>
        </div>
      </Grid>
      <Grid item component={Card} className={classes.card}>
        <div className={classes.text}>
          <div className={classes.title}>Total Value Locked</div>
          <div className={classes.price}>
            <div>${formatter.format(poolInfo?.tvl.toNumber())}</div>
            {/* <div className={classes.percent}>+410.7%{ArrowUpGreen}</div> */}
          </div>
        </div>
      </Grid>
      <Grid item className={classes.card}>
        {/* <div className={classes.text}>
          <div className={classes.title}>Volume</div>
          <div className={classes.price}>
            <div>$9,999,420.69</div>
            <div className={classes.percent}>-10.7%{ArrowDownRed}</div>
          </div>
        </div> */}
      </Grid>
    </Grid>
  );
};

export default PoolHeader;
