import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { SHORT_TWAP_MIN, SHORT_TWAP_MAX } from '../utils/utils';
import { getOracle } from '../utils/contracts';
import { useBnbPriceState } from '../state/bnbPriceContext';

const useTokenPrices = (poolTokens, tokenPriceId) => {
    const { account, library, chainId } = useWeb3React();
    const [balance, setBalance] = useState([]);
    const bnbPriceState = useBnbPriceState();
    const usdPrice = bnbPriceState?.[tokenPriceId]?.usd;

    useEffect(() => {
        const fetchBalances = async (tokens) => {
            if (!tokens.length) {
                return;
            }
            const contract = getOracle(library, chainId);

            const balances = await Promise.all(tokens.map(async (token) => {
                const price = await contract.methods.computeAverageTokenPrice(
                    token,
                    SHORT_TWAP_MIN,
                    SHORT_TWAP_MAX,
                ).call();
                return new BigNumber(price).div(2 ** 112).times(usdPrice);
            }));
            setBalance(balances);
        };

        if (account && library && usdPrice > 0) {
            fetchBalances(poolTokens);
        }
    }, [account, usdPrice, chainId, library, poolTokens]);

    return balance;
};

export default useTokenPrices;
