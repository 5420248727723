import React from 'react';
import { Grid } from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import { addresses } from '@project/contracts';

import PoolCard from './PoolCard';
import ConnectWallet from './ConnectWallet';

import { CHAIN_ID } from '../../constants/global.constants';

import { useStyles } from './styles.module';

const Dashboard = () => {
  const { chainId } = useWeb3React();
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.container}
      justifyContent="center"
    >
      {chainId === CHAIN_ID
        ? (
          <>
            <Grid item><h1>BiShares Index Pools</h1></Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              spacing={2}
              className={classes.content}
            >
              <PoolCard poolAddress={addresses[chainId].biT10} />
              <PoolCard poolAddress={addresses[chainId].apeSwap} />
            </Grid>
          </>
      )
        : <ConnectWallet />}
    </Grid>
  );
};

export default Dashboard;
