import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { abis } from '@project/contracts';
import { makeContract } from '../utils/utils';

const usePoolTokenBalances = (poolAddress, poolTokens) => {
  const { account, library } = useWeb3React();
  const [balance, setBalance] = useState([]);

  useEffect(() => {
    const fetchBalances = async tokens => {
      if (!tokens.length) {
        return;
      }
      const contract = makeContract(library, abis.indexpool, poolAddress);
      const balances = await Promise.all(tokens.map(token => contract.methods.getBalance(token).call()));
      const BNbalances = balances.map(val => new BigNumber(val));
      setBalance(BNbalances);
    };

    if (account && library) {
      fetchBalances(poolTokens);
    }
  }, [account, library, poolAddress, poolTokens]);

  return balance;
};

export default usePoolTokenBalances;
