import {
  makeStyles,
} from '@material-ui/core/styles';
import { theme } from '../../../theme';

export const useStyles = makeStyles(() => ({
    footer: {
        width: '100vw',
        backgroundColor: '#1E1F20',
        padding: '49px 33px',
        boxSizing: 'border-box',

    },
    title: {
        display: 'none',
        color: theme.palette.text.secondary,
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },

    link: {
        display: 'flex',
        alignItems: 'flex-end',
        color: theme.palette.text.secondary,
    },
}));
