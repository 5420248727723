import React, { useState, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Button } from '@material-ui/core';
import TokenList from '../../lists/tokenlist/TokenList.component.js';
import InputToken from '../../inputs/InputToken.js';
import { useStyles } from './BurnPanel.styles.js';
import { calcAllOutGivenPoolIn } from '../../../utils/bmath.js';
import useApprove from '../../../hooks/useApprove.js';
import useTokenBalance from '../../../hooks/useTokenBalance.js';
import useExitPool from '../../../hooks/useExitPool.js';

const MultiBurn = ({ poolInfo, inputValue, inputError, handleChange, poolAllowance }) => {
  const { library } = useWeb3React();
  const classes = useStyles();
  const [amounts, setAmounts] = useState(poolInfo.tokens.map(() => '0'));
  const { onExitPool } = useExitPool(
    poolInfo.address,
    library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether'),
    amounts,
  );
  const tokenBalance = useTokenBalance(poolInfo.address);

  const inputValueWei = library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether');
  const handleAmounts = useCallback(
    (inputAmount, _poolInfo) => {
      if (_poolInfo !== undefined && _poolInfo.balances.length > 0 && inputAmount > 0) {
        const _amounts = calcAllOutGivenPoolIn(poolInfo.balances, poolInfo.weights, poolInfo.totalSupply, inputAmount);
        setAmounts(_amounts);
      }
    },
    [poolInfo.balances, poolInfo.totalSupply, poolInfo.weights],
  );
  const { onApprove } = useApprove(
    poolInfo.address,
    library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether'),
    poolInfo.address,
  );

  const wrappedHandleChange = value => {
    handleChange({
      value,
      handleAmounts,
      setAmounts,
    });
  };

  return (
    <div>
      <div>Multi asset entry enables you to mint the {poolInfo.symbol} according to its asset allocation. This will require multiple approvals.</div>
      <div className={classes.inputContainerMulti}>
        <InputToken
          onChange={wrappedHandleChange}
          tokenInfo={{
            symbol: poolInfo.symbol,
            address: poolInfo.address,
          }}
          size="large"
          error={inputError}
          value={inputValue.toString()}
          gradient
          header={
            <div>
              Shares to
              <b> burn</b>
            </div>
          }
        />
      </div>
      <div className={classes.arrowContainer}>
        {' '}
        <ArrowDownwardIcon />
      </div>
      <div className={classes.container}>
        <div className={classes.containerTitle}>
          <div className={classes.containerTitleMain}>Redeem underlying assets</div>
          <div>Upon burn, you will receive all assets below</div>
        </div>
        <TokenList poolAddress={poolInfo.address} poolAmounts={amounts} poolTokens={poolInfo.tokens} />
      </div>
      {poolAllowance.toNumber() >= inputValueWei ? (
        Number(inputValue) > 0 ? (
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={inputError || inputValueWei <= 0 || inputValueWei > tokenBalance}
            onClick={onExitPool}
            className={classes.mintButton}
          >
            BURN {poolInfo.symbol}
          </Button>
        ) : (
          <Button variant="outlined" size="large" className={classes.mintButton}>
            Enter an Amount
          </Button>
        )
      ) : (
        <Button variant="contained" color="primary" size="large" onClick={onApprove} className={classes.mintButton}>
          APPROVE
        </Button>
      )}
    </div>
  );
};

export default MultiBurn;
