import { useMemo, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { abis, addresses } from '@project/contracts';
import { useToasts } from 'react-toast-notifications';
import { makeContract } from '../utils/utils';

import { ADDRESS_ZERO } from '../constants/global.constants';

const useSwapBnbForAllTokensMint = (indexPoolAddress, indexPoolTokensCount, poolAmountOut, setLoader) => {
  const { account, library, chainId } = useWeb3React();
  const { addToast } = useToasts();

  const intermediaries = useMemo(() => Array.from({ length: indexPoolTokensCount }, () => ADDRESS_ZERO), [
    indexPoolTokensCount,
  ]);
  const handleSwap = useCallback(async () => {
    await (async () => {
      addToast('Waiting for transaction success...', {
        appearance: 'info',
        autoDismiss: true,
      });
      const contract = makeContract(
        library,
        abis.uniswapRouterMinter,
        addresses[chainId][indexPoolAddress].uniswapRouterMinter,
      );
      let BNBvalue = await contract.methods
        .getMaxAmountForJoin(addresses[chainId].WBNB, intermediaries, indexPoolAddress, poolAmountOut)
        .call();
      // await contract.methods
      //   .swapETHForAllTokensAndMintExact(indexPoolAddress, intermediaries, poolAmountOut)
      //   .estimateGas({ from: account, value: BNBvalue.toString() });
      BNBvalue = Number(BNBvalue) + 100;
      await contract.methods
        .swapETHForAllTokensAndMintExact(indexPoolAddress, intermediaries, poolAmountOut)
        .send({ from: account, value: `${BNBvalue}` })
        .then(() => {
          addToast('Transaction Success!', {
            appearance: 'success',
            autoDismiss: true,
          });
          setLoader(false);
        })
        .catch(err => {
          if (err.message.includes('User denied transaction signature')) {
            addToast('Denied Transaction', {
              appearance: 'error',
              autoDismiss: true,
            });
            setLoader(false);
          } else {
            addToast('Transaction Failed', {
              appearance: 'error',
              autoDismiss: true,
            });
            setLoader(false);
          }
        });
    })();
  }, [account, addToast, chainId, indexPoolAddress, intermediaries, library, poolAmountOut, setLoader]);

  return { onSwapBnbForTokensAndMint: handleSwap };
};

export default useSwapBnbForAllTokensMint;
