
const addresses = {
  // local fork - depending on what chain you are forking you can copy the addresses from below.
  1337: {



  },
  // 97: {
  //   // BSC TESTNET

  //   // DO NOT DELETE
  //   factory: '0x6725F303b657a9451d8BA641348b6761A6CC7a17',
  //   router: '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
  //   WBNB: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
  //   MULTISIG: '0x60731C18EDd8a0Fd2A0d0C8B6462c8026e1939A7',
  //   uniswapV2oracle: '0x1165056A2FD62e850b832081906719C2E9E12d07',
  //   CAKE: '0x023669a7506594459d5007A2978e1dFF8078c9e4',
  //   VENUS: '0x61fc27A3012c03acD5A324eB6D910b34b9A11672',
  //   // AUTO: '0x98B15C716B4636E7D75b6ae15264597ACb7e2245',
  //   // BIFI: '0xBc176b28364320AAC3527c7533804f04Bb0e1B66',
  //   // TWT: '0x2A0D658b465a823d0EeF0EF414c70fcCEA6dA541',
  //   // BAKE: '0x3d81B793391E3e39291219b251591f1A384E8651',
  //   // BSCPAD: '0xECc8cA0720F1366A0566e2cd0661194d788EA297',
  //   // SAFEPAL: '0x1aF3516221EA8D20e161192634884b46FB357b70',
  //   // BUNNY: '0x38527eA89Ef201DA7ee134E20Abf23db6D38b77A',


  //   proxymanager: '0x00D5d9dEe4C12690301651c89E2ccAbA3D7ceD09',
  //   proxyaccesscontrol: '0xBDF1b07a4CDd8a9775f970578fF37fE36FEA240b',
  //   poolfactory: '0x3bB0574914Ae5eE8AD01a67d795e3691321b564d',
  //   controllerImplementation: '0xd4B13B1ab922bB34BC239b7a125264308750F356',
  //   controller: '0x4B61fC09E8DC9Ba4A6a3bB84c3d5fA0Fb465C682',
  //   poolInitializerImplementation: '0x415B11c07de7F86c575294d9EfbE0b6f30719232',
  //   tokensellerimplementation: '0x21c369E1d572d3d00E9c3b36B13542C0653deE7B',
  //   poolImplementation: '0x6F4a3BB9c28486087609d41519B0fb185E62A035',
  //   biT10_initializer: '0xCEc244420D365Bc0Ab29786ae6e2F291543D06D3',
  //   biT10: '0x1FF6b21f21EE6B7eDfaC5652b60DAFE060f6EEDE'

  // },

  56: {
    // BSC MAINNET
    /*
    // PANCAKESWAP
    factory: '0xBCfCcbde45cE874adCB698cC183deBcF17952812',  // PancakeSwap MAINNET uniswapV2factory.
    router: '0x10ED43C718714eb63d5aA57B78B54704E256024E',   // PancakeSwap MAINNET uniswapV2router.
    //uniswapV2oracle: '0x244F2A6cA291c66164D34a26e7b74f43893db0F2', // pancakeswap MAINNET oracle deployed at 9 juli
    */
    // APESWAP
    factory: '0x0841BD0B734E4F5853f0dD8d7Ea041c241fb0Da6',  // ApeSwap MAINNET uniswapV2factory.
    router: '0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7',   // ApeSwap MAINNET uniswapV2router.
    uniswapV2oracle: '0x7D1F68D7BA8A40B73EeB499E8D9D96145d1f6500', // apeswap MAINNET oracle deployed at 9 juli

    MULTISIG: '0x60731C18EDd8a0Fd2A0d0C8B6462c8026e1939A7',

    // FIRST FUND TOKENS
    WBNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    CAKE: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    SWIPE: '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
    BAKE: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    VENUS: '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63',
    ELLIPSIS: '0xA7f552078dcC247C2684336020c03648500C6d9F',
    BANANA: '0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
    SAFEPAL: '0xD41FDb03Ba84762dD66a0af1a6C8540FF1ba5dfb',
    TWT: '0x4B0F1812e5Df2A09796481Ff14017e6005508003',
    BIFI: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
    BISON: '0x19A6Da6e382b85F827088092a3DBe864d9cCba73',
    JDI: '0x0491648C910ad2c1aFaab733faF71D30313Df7FC',
    HIFI: '0x0A38bc18022b0cCB043F7b730B354d554C6230F1',
    BITFRESH: '0xa4F93159cE0A4B533b443c74b89967C60A5969F8',
    TAPE: '0xF63400ee0420ce5b1Ebdee0C942D7dE1C734a41f',
    LYPTUS: '0xBA26397cdFF25F0D26E815d218Ef3C77609ae7f1',
    DRAGONARY: '0xd9025e25Bb6cF39f8c926A704039D2DD51088063',

    '0x2a5F983706b6535ab5d69f003Eb271eC44b5E839': {
      proxymanager: '0xC19f03FeB19d10889d83c63c47C6Da43af5f38bC',
      proxyaccesscontrol: '0x266F86C516eAE7C23B5c0213A078d60cA2D89ADc',
      poolfactory: '0x392aBA3e8959F17533aFE9efE5F5b444f17397e4',
      controllerImplementation: '0xE2658f0e7DB82AB327fe806Ee1226e7dE1bA36EC',
      controller: '0x73a4aD4eD2F9d5FfD08A96403eE2A7F5Ff4B71DB',
      poolInitializerImplementation: '0x8C6E184dB8e1f0aB13bD21878255700e812Ad929',
      tokensellerimplementation: '0xa216E48Ff2690fb0CAcA82F458dD9C3088bbCD88',
      poolImplementation: '0x31A5fFc20AAB988f92b5684E895358Bd98D20F2F',

      biT10_initializer: '0x06C794E61ce7Cd286E21a8cde3596eBc1ca20991',
      biT10: '0x2a5F983706b6535ab5d69f003Eb271eC44b5E839',

      uniswapRouterBurner: '0x924AF62D2666625A197229c520D9981CE606c6a5',
      uniswapRouterMinter: '0x492829ff7692456437a7d2DF0b509581139a44A7',
    },

    '0xF19B6746769132926Ee82F47137aEB7A8702c4b9': {
      proxymanager: '0x4C8410D8aDdE1c9ed0239Df7AE496478e6eA6147',
      proxyaccesscontrol: '0xace2Edd31c9FA3077cc13510eC5FCe34E982eb00',
      poolfactory: '0x072C82aB5643D9b9d2AEb5deeBDEd85D977953E0',
      controllerImplementation: '0x356cd6C6aDa68D1e4EF7c854Ec1Ce9D1E5030d6c',
      controller: '0x3Feb696c695Faa752275Afb965dD7D9f22ff2fE0',
      poolInitializerImplementation: '0xd89A98F63c916EB660f5d6f1EFad9A36BEc22a62',
      tokensellerimplementation: '0xb98799BF5C25Ae7f2Ae5c41B80A812806CEc698E',
      poolImplementation: '0x0eff1A1D5057ff2f0E2E40e2cfF5d1Df75520f6C',

      bMathPublic: '0x4B026ff3fA4FdEe1C757ea084519Cb7988C4938d',
      uniswapRouterBurner: '0x924AF62D2666625A197229c520D9981CE606c6a5',
      uniswapRouterMinter: '0x492829ff7692456437a7d2DF0b509581139a44A7',

      apeSwapInitializer: '0x9B603518C03aea002543C8023392135c11b569e4',
      apeSwap: '0x8fD36BAE5FbE8b7461681fcB4D79d6C614433194',
    },

    biT10: '0x2a5F983706b6535ab5d69f003Eb271eC44b5E839',
    apeSwap: '0xF19B6746769132926Ee82F47137aEB7A8702c4b9',
  },
};

export default addresses;
