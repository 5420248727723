import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { useToasts } from 'react-toast-notifications';
import { abis } from '@project/contracts';

import { makeContract } from '../utils/utils';

const useExitPool = (poolAddress, poolAmountIn, minAmountsOut) => {
  const { account, library } = useWeb3React();
  const { addToast } = useToasts();

  const handleExit = React.useCallback(
    async () => {
      const exitPool = async (
        _poolAmountIn,
        _minAmountsOut,
      ) => {
        addToast('Waiting for transaction success...', {
          appearance: 'info',
          autoDismiss: true,
        });
        const contract = makeContract(library, abis.indexpool, poolAddress);
        await contract.methods.exitPool(
          _poolAmountIn.toString(),
          _minAmountsOut,
        ).send({ from: account }).then(() => {
          addToast('Transaction Success!', {
            appearance: 'success',
            autoDismiss: true,
          });

        }).catch(err => {
          if (err.message.includes('User denied transaction signature')) {
            addToast('Denied Transaction', {
              appearance: 'error',
              autoDismiss: true,
            });
          } else {
            addToast('Transaction Failed', {
              appearance: 'error',
              autoDismiss: true,
            });
          }
        });

      };

      await exitPool(
        poolAmountIn,
        minAmountsOut,
      );
    },
    [account, addToast, library, minAmountsOut, poolAddress, poolAmountIn],
  );

  return { onExitPool: handleExit };
};

export default useExitPool;
