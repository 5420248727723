import {
  makeStyles,
} from '@material-ui/core/styles';

export const useStyles = makeStyles({
    card: {
      padding: '32px 41px',
      alignItems: 'center',
    },

    avatar: {
        width: '120px',
        height: '120px',
        marginBottom: '8px',
    },

    avatarGroup: {
        margin: '24px 0',
    },
});
