import {
  makeStyles,
} from '@material-ui/core/styles';
import { theme } from '../../../../../theme';

export const useStyles = makeStyles({
    root: {
       minWidth: '280px',
    },
    avatar: {
        minWidth: '50px',
        minHeight: '50px',
        marginRight: '12px',
    },
    wrap: {
      marginBottom: '12px',
    },
    tokenName: {
      color: theme.palette.text.secondary,
      marginLeft: '8px',
    },
    row: {
      display: 'flex',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    tokenNameColumn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    text: {
      fontSize: '14px',
      color: theme.palette.text.secondary,
    },

});
