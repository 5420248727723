import {
  makeStyles,
} from '@material-ui/core/styles';
import { theme } from '../../../theme';

export const useStyles = makeStyles({
    card: {
        height: '100%',
        backgroundColor: '#DAA10E',
        color: theme.palette.text.primary,
        borderRadius: '32px',
        background: 'linear-gradient(180deg, #DAA10E 50%, #1E1F20 100%)',
    },
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'start',
        padding: '0 16px',
        // background: 'linear-gradient(90deg, rgba(0,0,0,0) 60%, rgba(219,165,23,0.542454481792717) 160%)',
        // borderBottom: theme.palette.border.color,
    },
    buttonGroup: {
        width: '50%',
    },
    button: {
        display: 'flex',
        fontSize: '18px',
        flexWrap: 'nowrap',
        // width: '100%',
        padding: '5px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '60px',
        color: 'black',
         '&:nth-child(1)': {
             fontSize: props => (props.currentActionTab === 'MINT' ? '24px' : '18px'),
             fontWeight: props => (props.currentActionTab === 'MINT' ? 700 : 500),
         },
        '&:nth-child(2)': {
            fontSize: props => (props.currentActionTab === 'BURN' ? '24px' : '18px'),
            fontWeight: props => (props.currentActionTab === 'BURN' ? 700 : 500),
        },
        '&:hover': {
            borderBottom: `solid 6px ${theme.palette.primary.main}`,
        },
    },
    content: {
        padding: '32px',
        color: theme.palette.text.main,
        backgroundColor: '#1E1F20',
        borderRadius: '32px',
    },
    nowrapper: {
        display: 'flex',
        flexWrap: 'nowrap',
    },
    difference: {
        color: `${theme.palette.green.main} !important`,
    },
});
