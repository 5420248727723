import React, { useMemo, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { abis, addresses } from '@project/contracts';
import { useToasts } from 'react-toast-notifications';
import { makeContract } from '../utils/utils';
import { ZERO_ADDRESS } from '../constants/chain.constants';

const useSwapTokensForAllTokensAndMintExact = (tokenIn, amountInMax, indexPoolTokens, indexPool, poolAmountOut, setLoader) => {
  const { account, library, chainId } = useWeb3React();
  const { addToast } = useToasts();

  const intermediaries = useMemo(
    () => indexPoolTokens.map(item => {
        if (
          item.toUpperCase() === tokenIn.toUpperCase()
          || item.toUpperCase() === addresses[chainId].WBNB.toUpperCase()
        ) {
          return ZERO_ADDRESS;
        }
        return addresses[chainId].WBNB;
      }),
    [indexPoolTokens, tokenIn, chainId],
  );
  const handleSwap = useCallback(async () => {
    await (async () => {
      addToast('Waiting for transaction success...', {
        appearance: 'info',
        autoDismiss: true,
      });
      const contract = makeContract(
        library,
        abis.uniswapRouterMinter,
        addresses[chainId][indexPool].uniswapRouterMinter,
      );
      let maxAmountIn = await contract.methods
        .getMaxAmountForJoin(tokenIn, intermediaries, indexPool, poolAmountOut)
        .call();
      maxAmountIn = Number(maxAmountIn) + 1000000;
      // await contract.methods
      //   .swapTokensForAllTokensAndMintExact(tokenIn, maxAmountIn, intermediaries, indexPool, poolAmountOut)
      //   .estimateGas({ from: account })
      //         .catch(err => {
      //           console.log(err);
      //           setLoader(false);
      //         });
      await contract.methods
        .swapTokensForAllTokensAndMintExact(tokenIn, `${maxAmountIn}`, intermediaries, indexPool, poolAmountOut)
        .send({ from: account })
        .then(() => {
          addToast('Transaction Success!', {
            appearance: 'success',
            autoDismiss: true,
          });
        })
        .catch(err => {
          if (err.message.includes('User denied transaction signature')) {
            addToast('Denied Transaction', {
              appearance: 'error',
              autoDismiss: true,
            });
            setLoader(false);
          } else {
            addToast('Transaction Failed', {
              appearance: 'error',
              autoDismiss: true,
            });
            setLoader(false);
          }
        });
    })();
  }, [account, addToast, chainId, intermediaries, library, tokenIn, indexPool, poolAmountOut, setLoader]);

  return { onSwapTokensForAllTokensAndMintExact: handleSwap };
};

export default useSwapTokensForAllTokensAndMintExact;
