import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';

import useTokenInfo from './useTokenInfo';
import usePoolTokenBalances from './usePoolTokenBalances';
import usePoolTokenWeights from './usePoolTokenWeights';
import useGetCurrentTokens from './useCurrentTokens';
import useTotalWeight from './useTotalWeight';
import useTotalSupply from './useTotalSupply';
import useGetSwapFee from './useGetSwapFee';
import useTokenPrices from './useTokenPrices';

import { getTokenAvatar } from '../utils/utils';

import { COMMON_BASE_TOKENS } from '../constants/chain.constants.js';
import useAllTokenInfo from './useAllTokenInfo.js';

const getTVL = (balances, prices) => {
  if (!balances.length || !prices.length || balances.length !== prices.length) {
    return new BigNumber(0);
  }
  return balances.reduce((acc, item, index) => acc.plus(item.div(1e18).times(prices[index])), new BigNumber(0));
};

const usePoolInfo = poolAddress => {
  const { account, library, chainId } = useWeb3React();
  const [info, setInfo] = useState();
  const { name, symbol } = useTokenInfo(poolAddress);
  const poolTokens = useGetCurrentTokens(poolAddress);
  const totalWeight = useTotalWeight(poolAddress);
  const totalSupply = useTotalSupply(poolAddress);
  const swapFee = useGetSwapFee(poolAddress);
  const poolTokenBalances = usePoolTokenBalances(poolAddress, poolTokens);
  const poolTokenWeights = usePoolTokenWeights(poolAddress, poolTokens);
  const poolTokenPrices = useTokenPrices(poolTokens, COMMON_BASE_TOKENS[0].priceId);
  const allTokensInfo = useAllTokenInfo(poolTokens);

  useEffect(() => {
    if (account && library && name && symbol && poolTokens && totalWeight && totalSupply) {
      const avatar = getTokenAvatar(poolAddress, chainId);
      const newInfo = {
        address: poolAddress,
        name,
        img: avatar,
        symbol,
        tokens: poolTokens,
        balances: poolTokenBalances,
        weights: poolTokenWeights,
        totalSupply,
        totalWeight,
        swapFee,
        tokenPrices: poolTokenPrices,
        tvl: getTVL(poolTokenBalances, poolTokenPrices),
        allTokensInfo,
      };
      setInfo(newInfo);
    }
  }, [
    account,
    chainId,
    library,
    name,
    poolAddress,
    poolTokenBalances,
    poolTokenWeights,
    poolTokens,
    swapFee,
    symbol,
    totalSupply,
    totalWeight,
    poolTokenPrices,
    allTokensInfo,
  ]);

  return info;
};

export default usePoolInfo;
