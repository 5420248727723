import {
  createTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';

const _theme = createTheme({
  root: {
     display: 'flex',
     alignItems: 'center',
     justifyContent: 'center',
  },
  typography: {
    h1: {
       fontWeight: '400',
    },
    h2: {

    },
    h3: {

    },
    h4: {

    },
    h5: {
      fontSize: '32px',
    },
    h6: {

    },
    body1: {
      fontSize: '24px',
    },
    body2: {
      fontSize: '20px',
    },

  },

  palette: {
      type: 'dark',

      primary: {
          main: '#DAA10E',
      },
      secondary: {
        main: '#2C2B2B',
      },
      background: {
          main: '#191919',
          cardFill: '#1E1F20',
      },
      header: {
        main: '#2C2B2B',
        border: '1px solid #707070',
      },
      subcontainer: {
        dark: '#2C2B2B',
        light: '#fafafa',
      },
      gradient: {
        main: '#DBA517',
      },
      footer: {
        main: '#191919',
      },
      paper: {
          main: '#242325',
      },
      text: {
          primary: '#fafafa',
          secondary: '#9F9F9F',
          dark: '#747474',
      },
      border: {
        color: '1px solid #DAA10E',
        radius: '5px',
        error: '1px solid #FF0000',
        highlight: '3px solid #DAA10E',
      },
      green: {
        main: '#00F600',
      },
  },
});

export const theme = responsiveFontSizes(_theme);
