import React, { useState, useEffect, useMemo } from 'react';
import {
 Grid, AppBar, Toolbar, Button as MuButton,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import Button from '../../../components/Button';

import ConnectWalletModal from '../../modal/ConnectWalletModal';
import LogOutModal from '../../modal/LogOutModal';

import BiSharesLogo from '../../../assets/logos/logo.png';
import { LogIn, Wallet } from '../../../constants/icons.constants';
import {
 PATH_INDEX, LINK_PATH_HOME, LINK_PATH_ABOUT,
} from '../../../constants/routes.constants';

import { useStyles } from './styles.module';

const Header = () => {
    const classes = useStyles();
    const { account } = useWeb3React();
    const [isConnectModal, setConnectModal] = useState(false);
    const [isLogOutModal, setLogOutModal] = useState(false);

    const walletAddress = useMemo(() => (
      account && `${account?.slice(0, 7) }...${account?.slice(account?.length - 4, account?.length)}`
    ), [account]);

    useEffect(() => account && setConnectModal(false), [account]);

    return (
      <AppBar position="relative" color="transparent" elevation={1}>
        <Toolbar className={classes.header}>
          <Grid
            container
            alignItems="center"
          >
            <Grid lg={1} md={2} item>
              <Link to={PATH_INDEX}>
                <img src={BiSharesLogo} alt="BiShares Index" />
              </Link>
            </Grid>
            <Grid lg={2} md={3} justifyContent="space-around" container item>
              <MuButton className={classes.link} href={LINK_PATH_HOME}>Home</MuButton>
              <MuButton className={classes.link} href={LINK_PATH_ABOUT}>About</MuButton>
              <MuButton className={classes.link} href={PATH_INDEX}>Funds</MuButton>
            </Grid>
            <Grid lg={9} md={7} justifyContent="flex-end" container item>
              {
                account
                  ? <Button className={classes.button} onClick={() => setLogOutModal(true)} outLine>{Wallet}{walletAddress}</Button>
                  : <Button className={classes.button} onClick={() => setConnectModal(true)} outLine>{LogIn}Connect Wallet</Button>
              }
            </Grid>
          </Grid>
          {isConnectModal && <ConnectWalletModal onClose={() => setConnectModal(false)} />}
          {isLogOutModal && <LogOutModal onClose={() => setLogOutModal(false)} title={walletAddress} account={account} />}
        </Toolbar>
      </AppBar>
    );
};

export default Header;
