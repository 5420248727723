import React, { useState, useMemo, useEffect } from 'react';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import BigNumber from 'bignumber.js';
import { addresses } from '@project/contracts';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useWeb3React } from '@web3-react/core';

import Button from '../../Button';
import InputToken from '../../inputs/InputToken.js';
import TokenListItem from '../tokenListItem.js';

import { MAX_UINT, isApproved } from '../../../utils/utils.js';

import useAllowance from '../../../hooks/useAllowance.js';
import useApprove from '../../../hooks/useApprove.js';
import useSwapTokensForAllTokensAndMintExact from '../../../hooks/swapTokensForAllTokensAndMintExact.js';
import useSwapBnbForAllTokensMint from '../../../hooks/useSwapBnbForAllTokensMint.js';
import useMaxAmountForJoin from '../../../hooks/useMaxAmountForJoin.js';
import useTokenBalance from '../../../hooks/useTokenBalance';

import { COMMON_BASE_TOKENS } from '../../../constants/chain.constants.js';
import { BNB, WBNB } from '../../../constants/global.constants.js';

import { useStyles } from './MintPanel.styles.js';

const RouterMint = ({ poolInfo, inputValue, setInputValue, handleChange, inputError }) => {
  const { library, chainId } = useWeb3React();
  const [mintWithThisToken, setMintWithThisToken] = useState(COMMON_BASE_TOKENS[0]);
  const [loader, setLoader] = useState(false);
  const [tokenSelectList, setTokenSelectList] = useState(false);
  const onMaxAmountForJoin = useMaxAmountForJoin();
  const currentTokenBalance = useTokenBalance(mintWithThisToken.address);
  const tokenList = useMemo(() => (
          poolInfo.allTokensInfo.filter(item => !poolInfo.tokens.includes(item.address))
  ), [poolInfo.allTokensInfo, poolInfo.tokens]);

  const [potentialBnbSwappedAmount, setPotentialBnbSwappedAmount] = useState(new BigNumber(0));
  const classes = useStyles();

  const allowance = useAllowance(addresses[chainId][poolInfo.address].uniswapRouterMinter, mintWithThisToken.address);

  const { onApprove } = useApprove(
    addresses[chainId][poolInfo.address].uniswapRouterMinter,
    MAX_UINT,
    mintWithThisToken.address,
    setLoader,
  );
  const isTokenApproved = useMemo(() => isApproved(allowance), [allowance]);
  const handleMintWithToken = token => {
    setMintWithThisToken(token);
    setInputValue('');
    setPotentialBnbSwappedAmount(new BigNumber(0));
    setTokenSelectList(false);
  };
  const onApproveHandler = async () => {
    setLoader(true);
    await onApprove();
  };

  const { onSwapTokensForAllTokensAndMintExact } = useSwapTokensForAllTokensAndMintExact(
    mintWithThisToken.address,
    potentialBnbSwappedAmount.toString(),
    poolInfo.tokens,
    poolInfo.address,
    library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether'),
    setLoader,
  );

  const { onSwapBnbForTokensAndMint } = useSwapBnbForAllTokensMint(
    poolInfo.address,
    poolInfo.tokens.length,
    library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether'),
    setLoader,
  );

  const wrappedHandleChange = value => {
    handleChange({ value, setPotentialBnbSwappedAmount });
  };
  const handleMint = () => {
    setLoader(true);
    if (mintWithThisToken.symbol === BNB || mintWithThisToken.symbol === WBNB) {
      onSwapBnbForTokensAndMint();
    } else {
      onSwapTokensForAllTokensAndMintExact();
    }
  };

  useEffect(() => {
    if (inputValue) {
      (async () => {
        const result = await onMaxAmountForJoin(
          mintWithThisToken.address,
          poolInfo.address,
          poolInfo.tokens,
          library.utils.toWei(inputValue === '' ? '0' : inputValue, 'ether'),
        );
        setPotentialBnbSwappedAmount(result);
      })();
    }
  }, [inputValue, mintWithThisToken.address, poolInfo.address, poolInfo.tokens, library.utils, onMaxAmountForJoin]);

  return (
    <div>
      <div>Use any single asset listed to mint the dETF token in one transaction.</div>
      <div className={classes.container}>
        {!tokenSelectList ? (
          <>
            <div className={classes.inputContainerTop}>
              <InputToken
                poolInfo={poolInfo}
                tokenInfo={mintWithThisToken}
                size="large"
                disabled
                valueWei
                error={inputError}
                header={<div><b>Mint</b> with</div>}
                value={potentialBnbSwappedAmount.toString()}
                tokenSelectList={tokenSelectList}
                setTokenSelectList={setTokenSelectList}
                maxButton
              />
            </div>
            <div className={classes.arrowContainer}>
              {' '}
              <ArrowDownwardIcon />
            </div>
            <div className={classes.inputContainer}>
              <InputToken
                onChange={wrappedHandleChange}
                tokenInfo={{
                  symbol: poolInfo.symbol,
                  address: poolInfo.address,
                }}
                size="large"
                error={inputError}
                value={inputValue.toString()}
                gradient
                header={<div>Shares to<b> mint</b></div>}
              />
            </div>
          </>
        ) : (
          <div>
            <Button>
              <NavigateBeforeIcon onClick={() => setTokenSelectList(false)} />
            </Button>
            <div className={classes.selectContainer}>
              {tokenList.map(token => (
                <TokenListItem
                  token={token}
                  key={token.address}
                  poolInfo={poolInfo}
                  handleMintWithToken={handleMintWithToken}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      {Number(inputValue) > 0 ? (
        isTokenApproved || mintWithThisToken.symbol === BNB ? (
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleMint}
            loader={loader}
            disabled={!Number(currentTokenBalance)}
            className={classes.mintButton}
          >
            MINT {poolInfo.symbol}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="large"
            loader={loader}
            className={classes.mintButton}
            onClick={onApproveHandler}
          >
            Approve {mintWithThisToken.symbol}
          </Button>
        )
      ) : (
        <Button variant="outlined" size="large" className={classes.mintButton} disabled>
          Enter an Amount
        </Button>
      )}
    </div>
  );
};

export default RouterMint;
