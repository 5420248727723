import React from 'react';
import { Grid } from '@material-ui/core';

import TokenInfo from './TokenInfo';

import { useStyles } from './styles.module';

const PoolContent = ({ poolInfo }) => {
  const classes = useStyles();

  if (!poolInfo) return null;

  return (
    <Grid
      container
      item
      direction="column"
      className={classes.content}
    >
      <Grid item spacing={2} container direction="column" className={classes.text}>
        <Grid item className={classes.title}>Asset Allocation</Grid>
        <Grid item className={classes.description}>Breakdown of total assets in this index</Grid>
      </Grid>
      <Grid container spacing={6} item>
        {
          poolInfo.tokens.map((token, index) => {
            const usdPrice = poolInfo.tokenPrices[index];
            const balance = poolInfo.balances[index];
            const weight = poolInfo.weights[index];

            return (
              <Grid container item lg={6} key={token}>
                <TokenInfo
                  poolInfo={poolInfo}
                  usdPrice={usdPrice}
                  weight={weight}
                  balance={balance}
                  poolToken={token}
                />
              </Grid>
            );
          })
        }
      </Grid>
    </Grid>
  );
};

export default PoolContent;
