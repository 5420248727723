import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  HashRouter,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { ToastProvider } from 'react-toast-notifications';

import { theme } from './theme';

import Layout from './containers/Loyout';
import Funds from './containers/Funds';
import Dashboard from './containers/dashboard';
import AuthorizationWrap from './containers/AuthorizationWrap';

import { BnbPriceContextProvider } from './state/bnbPriceContext';
import { CurrentBlockContextProvider } from './state/currentBlockContext';

import { PATH_INDEX, PATH_FOUNDS } from './constants/routes.constants';

const App = () => (
  <HashRouter>
    <AuthorizationWrap>
      <ThemeProvider theme={theme}>
        <ToastProvider
          autoDismiss
          autoDismissTimeout={6000}
          placement="bottom-center"
        >
          <CurrentBlockContextProvider>
            <BnbPriceContextProvider>
              <Layout>
                <Switch>
                  <Route path={PATH_INDEX} exact component={Dashboard} />
                  <Route path={PATH_FOUNDS} exact component={Funds} />
                </Switch>
              </Layout>
            </BnbPriceContextProvider>
          </CurrentBlockContextProvider>
        </ToastProvider>
      </ThemeProvider>
    </AuthorizationWrap>
  </HashRouter>
  );

export default App;
