import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { abis } from '@project/contracts';
import { useToasts } from 'react-toast-notifications';

import { makeContract } from '../utils/utils';

import { MAX_UINT256 } from '../constants/global.constants';

const useApprove = (spender, amount, contractAddress, setLoader) => {
  const { account, library } = useWeb3React();
  const { addToast } = useToasts();

  const handleApprove = useCallback(async () => {
    await (async () => {
      const contract = makeContract(library, abis.erc20, contractAddress);
      await contract.methods
        .approve(spender, MAX_UINT256)
        .send({ from: account })
        .then(() => {
          addToast('Transaction Success!', {
            appearance: 'success',
            autoDismiss: true,
          });
          setLoader(false);
        })
        .catch(err => {
          if (err.message.includes('User denied transaction signature')) {
            addToast('Denied Transaction', {
              appearance: 'error',
              autoDismiss: true,
            });
            setLoader(false);
          } else {
            addToast('Transaction Failed', {
              appearance: 'error',
              autoDismiss: true,
            });
            setLoader(false);
          }
        });
    })();
  }, [spender, contractAddress, account, addToast, library, setLoader]);

  return { onApprove: handleApprove };
};

export default useApprove;
