import React from 'react';
import { Grid } from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams } from 'react-router-dom';

import InfoCard from './InfoCard';
import ActionCard from '../../components/cards/actioncard/ActionCard.component';

import usePoolInfo from '../../hooks/usePoolInfo';

import { CHAIN_ID } from '../../constants/global.constants';

import { useStyles } from './styles.module';

const Funds = () => {
  const { address } = useParams();
  const { chainId } = useWeb3React();
  const classes = useStyles();
  const poolInfo = usePoolInfo(address);

  return (
    <Grid
      container
      spacing={4}
      alignItems="flex-start"
      justifyContent="center"
      className={classes.container}
    >
      {(poolInfo && chainId === CHAIN_ID) ? (
        <>

          <InfoCard poolInfo={poolInfo} />
          <Grid item lg>
            <ActionCard poolInfo={poolInfo} />
          </Grid>
        </>
      )
      : (<CircularProgress className={classes.loader} />)}

    </Grid>
  );
};

export default Funds;
